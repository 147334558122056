import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { Helmet } from 'react-helmet'
import logo from './source/logo.png'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <>
    <Helmet>
      <link rel="icon" href="%PUBLIC_URL%/logo192.png" />
      <title>Xi Chen</title>
    </Helmet>
    <App />
  </>

)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
