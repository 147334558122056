import React from 'react'
import { motion } from 'framer-motion'
import '../css/Publications.scss'

const projects = [
  {
    title: 'TAClarity',
    description:
      'An AI-powered teaching assistant tool that streamlines student inquiries through voice input and leverages LLM technology to analyze and highlight key discussion points for teaching assistants.',
    link: 'https://tacode.vizpi.org/',
    img: 'https://fastly.jsdelivr.net/gh/yingchenxing/images@main/17361464721091736146470901.png',
  },
  {
    title: 'Course Planner',
    description:
      'An app that can extract learning resources from the web and generate a course plan for all students',
    link: 'https://courseplanner.11chen.link/app',
    img: 'https://fastly.jsdelivr.net/gh/yingchenxing/images@main/17361445140731736144296663.png',
  },
  {
    title: 'Ditch',
    description:
      'Ditch can make a phone call to your service provider and help you cancel your service',
    link: 'https://devpost.com/software/ditch',
    img: 'https://fastly.jsdelivr.net/gh/yingchenxing/images@main/17361448660601736144842672.png',
  },
  {
    title: 'VTHack 2022',
    description:
      'A system that allows you to get the real-time traffice information of every dinning hall in Virginia Tech',
    link: 'https://github.com/yingchenxing/2022_VTHack',
    img: 'https://fastly.jsdelivr.net/gh/yingchenxing/images@main/17361452760411736145244532.png',
  },
]

function Projects() {
  return (
    <div className="publications">
      {projects.map((project, index) => (
        <motion.div
          className="publication"
          key={index}
          initial={{ opacity: 0, y: 50, overflow: 'hidden' }}
          animate={{ opacity: 1, y: 0, overflow: 'hidden' }}
          transition={{ duration: 0.05, delay: index * 0.2 }}
          whileHover={{ scale: 1.05 }}>
          <img
            src={project.img}
            alt={project.title}
            className="publication-image"
          />
          <div className="publication-content">
            <h3 className="publication-title">
              <a href={project.link} target="_blank" rel="noopener noreferrer">
                {project.title}
              </a>
            </h3>
            <p className="publication-description">{project.description}</p>
          </div>
        </motion.div>
      ))}
    </div>
  )
}

export default Projects
