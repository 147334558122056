import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import './css/Main.css'

function Main({ onTitleClick }) {
  useEffect(() => {
    const textElements = document.querySelectorAll('ul li a')
    textElements.forEach((text) => {
      text.innerHTML = text.innerText
        .split('')
        .map((letter) => `<span>${letter}</span>`)
        .join('')
    })

    const spanElements = document.querySelectorAll('ul li a span')
    spanElements.forEach((e) => {
      const duration = Math.random() * 5
      e.style.animationDuration = 0.25 + duration + 's'
      e.style.animationDelay = 0.25 + duration + 's'
    })
  }, [])

  return (
    <motion.div
      className="Main"
      initial={{ width: '100%' }}
      animate={{ width: '50%' }}
      transition={{ duration: 10, ease: 'easeInOut' }}>
      <div className="MainContent">
        <header className="header">
          <h1>Xi Chen</h1>
          <h2>Full-Stack Developer, Class of 2025</h2>
        </header>
        <ul>
          <li style={{ '--clr': '#00ade1' }}>
            <a
              href="#"
              data-text="&nbsp;About"
              onClick={() => onTitleClick('About')}>
              &nbsp;About
            </a>
          </li>
          <li style={{ '--clr': '#00dc82' }}>
            <a
              href="#"
              data-text="&nbsp;Skills"
              onClick={() => onTitleClick('Skills')}>
              &nbsp;Skills
            </a>
          </li>
          <li style={{ '--clr': '#ffdd1c' }}>
            <a
              href="#"
              data-text="&nbsp;Publications"
              onClick={() => onTitleClick('Publications')}>
              &nbsp;Publications
            </a>
          </li>
          <li style={{ '--clr': '#ff6493' }}>
            <a
              href="#"
              data-text="&nbsp;Resume"
              onClick={() => onTitleClick('Resume')}>
              &nbsp;Resume
            </a>
          </li>{' '}
          <li style={{ '--clr': '#dc00d4' }}>
            <a
              href="#"
              data-text="&nbsp;Projects"
              onClick={() => onTitleClick('Projects')}>
              &nbsp;Projects
            </a>
          </li>
          {/* <li style={{ '--clr': '#ff7b00' }}>
            <a
              href="#"
              data-text="&nbsp;Contact"
              onClick={() => onTitleClick('Contact')}>
              &nbsp;Contact
            </a>
          </li> */}
        </ul>
      </div>
    </motion.div>
  )
}

export default Main
