import React from 'react'
import profilePic from '../source/profile.jpg'
import '../css/About.scss'

function About() {
  return (
    <div className="AboutContent">
      <img src={profilePic} alt="Profile" className="ProfilePic" />
      <div className="PersonalInfo">
        <div className="communications">
          <a
            className="comm_link"
            href="https://www.linkedin.com/in/xi-chen-515156231/">
            <img
              alt="LinkedIn"
              src="https://img.shields.io/badge/LinkedIn-%230077B5.svg?&style=for-the-badge&logo=linkedin&logoColor=white"
              style={{ width: '100px', height: '27px' }}
            />
          </a>

          <a className="comm_link" href="mailto:xander11chan@gmail.com">
            <img
              alt="Gmail"
              src="https://img.shields.io/badge/Gmail-%23D14836.svg?&style=for-the-badge&logo=gmail&logoColor=white"
              style={{ width: '100px', height: '27px' }}
            />
          </a>

          <a className="comm_link" href="https://github.com/yingchenxing">
            <img
              alt="GitHub"
              src="https://img.shields.io/badge/GitHub-%23181717.svg?&style=for-the-badge&logo=github&logoColor=white"
              style={{ width: '100px', height: '27px' }}
            />
          </a>
        </div>
        <p>
          Hello! I'm Xi Chen, a Bachelor of Science student in Computer Science
          at the University of Virginia, graduating in 2025. I previously
          studied at Virginia Tech, where I specialized in computer science. My
          main focus is web development, with proficiency in Java and
          JavaScript. I am currently seeking full-time employment opportunities
          for 2025.
        </p>

        <p>
          I am currently working at CollegeBot.ai as a Software Engineer Intern,
          where I participate in the development of LLM applications and RAG
          (Retrieval-Augmented Generation) systems. Additionally, I am
          conducting research in the{' '}
          <a href="https://chensivan.github.io/" target="_blank">
            Programming with Intelligent Machines & Environments Lab (PRIME)
          </a>{' '}
          at Virginia Tech, under the guidance of Prof. Yan Chen. My research
          focuses on developing advanced Human-AI systems to improve programming
          education.
        </p>
        <p>
          Outside of academics and professional work, I enjoy photograph,
          hiking, and snowboarding. These activities allow me to stay active and
          inspired, providing a balance to my technical pursuits. For more
          details about my experience and projects, please refer to my portfolio
          and GitHub.
        </p>
      </div>
    </div>
  )
}

export default About
